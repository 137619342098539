import React, { useState, useEffect } from 'react';
import DealCard from './DealCard';
import Scroll from './Scroll';
import axios from 'axios';
import drinkslogo from "../images/drinkslogo.png";

const Home = () => {
  const [mealDeals, setMealDeals] = useState([])
  const [coldDrinks, setDrinks] = useState([])
  const [announcements, setAnnouncements] = useState([])
  const [announcementText, setAnnouncementsText] = useState("");


  useEffect(() => {
    getDeals();
    getDrinks();
    getAnnouncements();
  }, [])
  const getDeals = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/deals`)
      setMealDeals(response.data)
    }
    catch (err) {
      console.log(`error while fetching deals ===> ${err}`)
    }
  }

  const getDrinks = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/drinks`)
      setDrinks(response.data)
    }
    catch (err) {
      console.log(`error while fetching deals ===> ${err}`)
    }
  }

  const getAnnouncements= async()=>{
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/announcements`)

      if(response.data.length >= 1){
        setAnnouncements(response.data)
      }
      else{
        setAnnouncementsText("There are currently no announcements :)")
      }
      
    }
    catch (err) {
      console.log(`error while fetching deals ===> ${err}`)
    }
  }
 
  let cards;
  
  try{
    cards = mealDeals.map((item) => {
      return (
        <div key={item._id}>
          <DealCard item={item} />
        </div>
      )
    })

  }
  catch(err){
    console.log(err)
  }
  
  let drinksUnwrapped;
  try{
    drinksUnwrapped = coldDrinks.map((item) => {
      return (
        <div key={item.id}>
          <p><b>{item.title}:</b></p>
          <p><i>{item.flavours}:</i></p>
          <p>{item.price}</p>
        </div >
  
      )
    })
  } 
  catch(err){
    console.log(err)
  }

  let announcementsUnwrapped; 

  try{
    announcementsUnwrapped = announcements.toReversed().map((a)=>{
      return(
        <div key={a._id} className='announcement-card'>
          <h2 className='announcement-smaller-title'>{a.title}</h2>
          <p className='announcement-text'>{a.details}</p>
        </div>
      )
    })
  }
  catch(err){
    console.log(err)
  }

  return (
    <div>

      <h1 className="title">Harrisville Takeaway & General Store</h1>
      <Scroll height={75}>

        <div className='HomePage'>
          <div className="topSection">
            <div className="AnnouncementsHome">
              <h2 className='announcement-title'>Announcements 💬</h2>
              {announcementText !== "" ? <p className='announcement-text' style={{color:'white'}}>{announcementText}</p> : <></>}
              {announcements.length > 0 && announcementText.length === "" ? <>Loading...</> : announcementsUnwrapped }
              
            </div>

            <div className='drinks-card'>
            <h2 className='smaller-title' style={{textAlign:"center"}}> <span>Cold Drinks! <img src={drinkslogo} alt="drinkslogo" width="45%" /></span></h2>
              <div className='drinks'>
              {coldDrinks.length > 0 ? drinksUnwrapped : <>Loading...</>}
              

              </div>

            </div>

          </div>

          {/* meal deals */}
          <h2 className="title-left">Current Meal Deals</h2>
          <div className='MealDealHome'>
            {mealDeals.length > 0 ? cards : <>Loading...</>}
          </div>
        </div>
      </Scroll>
    </div>
  );
}

export default Home;